<template>
    <div style="margin-bottom:2rem;">
        <div class="flex wrap">
            <div class="col3" style="height:500px;" @click="go" v-for="(item,index) in data" :key="index">
                <Item :data="item" ></Item>
            </div>

        </div>
        <More></More>
    </div>
</template>
<script>
import Item from "@/components/columnItem.vue"
import More from "@/components/More.vue"
export default {
    components:{Item,More},
    data(){
        return{
            data:[
                    {url:'case/3-1.png',title:'微量分析，证实自己清白！',time:'2019-04-24',content:`某市的路口发生一起交通事故，经警方现场初步侦查，疑似林某骑驶地自行车与王某驾驶地货车发生刮擦，林某倒地受伤，后经医院抢救无效死亡。货车司机王某表示自行车在经过货车时忽然翻车倒地`},

                ]
        }
    },
    methods:{
        go(){
            let pathStr = '/case/mic_case/1'
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>